import React from "react"

import { Heading, Stack, useMediaQuery } from "@kiwicom/orbit-components"

import { ChecklistCompletion, ChecklistSelectionQuery } from "api/checklists"

import { useBreakOutFilter } from "components/generic/break-out-filter"

import {
  StateStoredInUrl,
  arrayField,
  arrayFieldWithShorthand,
  enumLoader,
} from "utils/url-state"

import { ChecklistTable } from "../table"
import { useChecklistFilters } from "./filters"

export const urlStoreForChecklists =
  new StateStoredInUrl<ChecklistSelectionQuery>(
    [
      arrayField("vehicleId", Number),
      arrayField("type", String),
      arrayFieldWithShorthand("completion", enumLoader(ChecklistCompletion), {
        "~incomplete~": [
          ChecklistCompletion.NOT_STARTED,
          ChecklistCompletion.PARTIALLY_COMPLETED,
        ],
      }),
    ],
    () => ({})
  )

export const ChecklistListView = () => {
  const [checklistQuery, setChecklistQuery] = urlStoreForChecklists.useState({
    completion: [
      ChecklistCompletion.NOT_STARTED,
      ChecklistCompletion.PARTIALLY_COMPLETED,
    ],
  })
  const { isTablet } = useMediaQuery()

  const { mainFilterButton, activeFilterDropDowns } = useBreakOutFilter({
    allFilters: useChecklistFilters(),
    query: checklistQuery,
    setQuery: setChecklistQuery,
  })

  return (
    <Stack
      direction="column"
      spacing="medium"
      largeDesktop={{ spacing: "medium" }}
    >
      <Stack direction="row">
        <Heading type="title3">Jobs</Heading>
        <Stack direction="row" justify="end" inline shrink>
          {isTablet && activeFilterDropDowns}
          {mainFilterButton}
        </Stack>
      </Stack>
      {!isTablet && activeFilterDropDowns?.length != 0 && (
        /* On narrow mobile screens, the tabs go on the row below */
        <Stack direction="row" align="center" wrap>
          {activeFilterDropDowns}
        </Stack>
      )}
      <ChecklistTable query={checklistQuery} />
    </Stack>
  )
}
