// @ts-strict-ignore
import { useEffect, useState } from "react"

import { formatInTimeZone } from "date-fns-tz"

import { getDefaultTimezone } from "./date-utils"

// This goes in the URL if the state is empty. It lets us distinguish between "we've just freshly
// loaded the page without anything after the hash, so use the default state" from "we've unselected
// everything, so show us everything". The latter case is where we'd put this in the URL hash.
const NOFILTER = "nofilter"

interface Field<S> {
  addTo: (params: URLSearchParams, state: S) => void
  getFrom: (params: URLSearchParams, state: S) => void
}

type Loader<T> = (raw: string) => T

/**
 * Utility class for mirroring a state object into the URL hash in the browser address bar. If the
 * object is changed, the URL hash is updated, and if the URL hash is changed, the state is updated.
 *
 * You need to provide an array of `Field` definitions, which you can build using the `scalarField`,
 * `arrayField` etc functions below.
 *
 *
 * Here's a simple example. We have a `WidgetSearch` object, and we want to save its contents in the
 * URL.
 *
 *     interface WidgetSearch {
 *       query?: string
 *       weight?: number
 *       tags?: string[]
 *       includeArchived: boolean
 *     }
 *
 *  We need to define how to store each field in the URL and parse it back. For arrays we use
 *  `arrayField`, for single values we use `scalarField`. The second argument to each field
 *  constructor is the "loader", which is a function that takes a string from the URL and returns a
 *  parsed value. For strings and numbers this can just be the built-in `String` and `Number`
 *  functions. For booleans and enums, you can use `boolLoader` and `enumLoader`, below.
 *
 *  For our fictional `WidgetSearch` it would look like this:
 *
 *     const urlStore = new StateStoredInUrl<WidgetSearch>(
 *       [
 *         scalarField("query", String),
 *         scalarField("weight", Number),
 *         arrayField("tags", String),
 *         scalarField("includeArchived", boolLoader),
 *       ],
 *       () => ({})
 *     )
 *
 *  Then you can use it like this:
 *
 *     const [query, setQuery] = urlStore.useState({})
 *
 *  and it behaves like a normal React `useState`, but synced with the URL hash. If you call
 *  `setQuery`, the hash is updated. If at page load time there's a hash in the URL, the initial
 *  state reflects that. And if the user updates the hash while the page is open, the state is
 *  changed (and so your component will re-render).
 */
export class StateStoredInUrl<S> {
  constructor(public fields: Field<S>[], public getEmptyState: () => S) {}

  stateToString(state: S): string {
    const params = new URLSearchParams()
    for (const field of this.fields) {
      field.addTo(params, state)
    }
    return params.toString()
  }

  stateFromString(paramStr: string): S {
    const params = new URLSearchParams(paramStr)
    const state = this.getEmptyState()
    for (const field of this.fields) {
      try {
        field.getFrom(params, state)
      } catch (e) {
        console.error(e)
      }
    }
    return state
  }

  /**
   * Returns the state object currently stored in the browser URL hash, or returns `null` (NOT an
   * empty state `{}`) if there is no state in the URL
   */
  loadFromCurrentLocation(): S {
    if (typeof window == "undefined" || !window?.location) {
      return null // If we're not running in a browser, do nothing
    } else if (window.location.hash == `#${NOFILTER}`) {
      // There's #nofilter in the URL, show everything
      return this.getEmptyState()
    } else if (window.location.hash) {
      // There's a filter in the URL, load that
      return this.stateFromString(window.location.hash.substring(1) ?? "")
    } else {
      // No filter in the URL, use the default filters
      return null
    }
  }

  /**
   * Use this as you would a normal `React.useState` call. The state object will be mirrored to the
   * URL hash, and changes to the URL hash will be mirrored in the state object.
   */
  useState(defaults: S): [S, (state: S) => void] {
    const [state, setState] = useState<S>(() => {
      // When we first render, look at the URL hash, and merge that in with the defaults, giving
      // priority to the values from URL
      return this.loadFromCurrentLocation() ?? defaults
    })
    const [urlInitialised, setUrlInitialised] = useState<boolean>(false)

    const expectedHash = "#" + (this.stateToString(state) || NOFILTER)
    useEffect(() => {
      if (typeof window == "undefined" || !window?.location) {
        return // If we're not running in a browser, do nothing
      }

      // If the state is modified, update the URL hash
      if (window.location.hash != expectedHash) {
        // The first time we change URL, we use replace(), so that there's no entry in browser
        // history between when the page loaded and when we saved initial state to URL. Afterwards
        // we just set the hash, so the user can navigate back in the state history using normal
        // browser back/forward buttons.
        if (!urlInitialised && !window.location.href.includes("#")) {
          window.location.replace(window.location.href + expectedHash)
          setUrlInitialised(true)
        } else {
          window.location.hash = expectedHash
        }
      }

      // If the URL hash is modified, update the state
      const listener = () => {
        if (window?.location.hash != expectedHash) {
          setState(this.loadFromCurrentLocation() ?? this.getEmptyState())
        }
      }
      window.addEventListener("hashchange", listener)
      return () => window.removeEventListener("hashchange", listener)
    }, [expectedHash])

    return [state, setState]
  }
}

/**
 * Defines how scalar fields (strings, numbers, booleans) are saved to the URL hash
 */
export const scalarField = <S, T>(key: string, loader: Loader<T>) => ({
  addTo(params: URLSearchParams, state: S) {
    if (state[key] != null) {
      params.set(key, state[key].toString())
    }
  },
  getFrom(params: URLSearchParams, state: S) {
    const raw = params.get(key)
    if (raw != null) {
      state[key] = loader(raw)
    }
  },
})

/**
 * Defines how array fields are saved to the URL hash. Every value becomes a separate param (so the
 * URL will include e.g. "?status=open&status=closed")
 */
export const arrayField = <S, T>(key: string, loader: Loader<T>) => ({
  addTo(params: URLSearchParams, state: S) {
    if (state[key] != null) {
      for (const element of state[key]) {
        if (element != null) {
          params.append(key, element.toString())
        }
      }
    }
  },
  getFrom(params: URLSearchParams, state: S) {
    const elems = params
      .getAll(key)
      .map(loader)
      .filter((v) => v != null)
    if (elems.length > 0) {
      state[key] = elems
    } else {
      delete state[key]
    }
  },
})

export const dateRangeField = <S,>(key: string) => ({
  addTo(params: URLSearchParams, state: S) {
    if (state[key] != null) {
      if ("start" in state[key] && "end" in state[key]) {
        params.set(
          `${key}.start`,
          formatInTimeZone(state[key].start, getDefaultTimezone(), "yyyy-MM-dd")
        )
        params.set(
          `${key}.end`,
          formatInTimeZone(state[key].end, getDefaultTimezone(), "yyyy-MM-dd")
        )
      }
    }
  },
  getFrom(params: URLSearchParams, state: S) {
    if ("start" in state[key] && "end" in state[key]) {
      const rawStart = params.get(`${key}.start`)
      const rawEnd = params.get(`${key}.end`)
      state[key].start = new Date(rawStart)
      state[key].end = new Date(rawEnd)
    }
  },
})

/**
 * Like `arrayField`, but allows for shorthand for certain common combinations of values. We use
 * this e.g. to allow `?status=~open~` as a shorthand for a full enumeration of all statuses that
 * count as "open". This just makes the URLs tidier and easier to edit by hand
 */
export const arrayFieldWithShorthand = <S, T>(
  key: string,
  loader: Loader<T>,
  shorthand: Record<string, T[]>
) => {
  const fallback = arrayField(key, loader)
  const asString = (v: T[]) => new Array(v).sort().join()
  return {
    addTo(params: URLSearchParams, state: S) {
      const match =
        state[key] &&
        Object.entries(shorthand).find(
          ([_, values]) => asString(values) == asString(state[key])
        )
      if (match) {
        params.set(key, match[0])
      } else {
        fallback.addTo(params, state)
      }
    },
    getFrom(params: URLSearchParams, state: S) {
      const elems = params.getAll(key)
      const match =
        elems.length == 1 &&
        Object.entries(shorthand).find(([short]) => elems[0] == short)
      if (match) {
        state[key] = match[1]
      } else {
        fallback.getFrom(params, state)
      }
    },
  }
}

export const boolLoader = (raw: string): boolean => raw.toLowerCase() == "true"

export const enumLoader = <T,>(enumType: T) => {
  const reverseLookup = Object.fromEntries(
    Object.entries(enumType).map(([key, value]) => [value, key])
  )
  return (raw: string) => {
    // All this does is validate that the string is part of the enum. The returned string is the
    // same as the input parameter (as long as it's valid)
    return reverseLookup[raw] ? raw : null
  }
}
