import React from "react"

import { ChecklistListView } from "components/maintenance/checklists/list"
import { MaintenancePage } from "components/maintenance/layout"

import { MaintenancePermissions } from "types/issue"

const Page = () => (
  <MaintenancePage
    fullScreen
    title="Jobs"
    accessControl={(permissions: MaintenancePermissions) =>
      permissions.maySearchChecklists
    }
  >
    <ChecklistListView />
  </MaintenancePage>
)

export default Page
