// @ts-strict-ignore
import * as icons from "@kiwicom/orbit-components/icons"

import { ChecklistCompletion, ChecklistSelectionQuery } from "api/checklists"

import {
  Filter,
  MultiselectFilter,
  SiftableMultiselectFilter,
} from "components/generic/break-out-filter"

import { checklistTypeToLabel } from "types/checklist"
import { LocationType } from "types/location"

import { fetchLocationOptions } from "utils/location-utils"
import { useGlobalFetcher } from "utils/state-utils"

import { isMaintainableVehicle } from "../shared/utils"

/**
 * This is the set of available Checklist filters
 */
export const useChecklistFilters = (): Filter<
  ChecklistSelectionQuery,
  unknown
>[] => {
  const { data: vehicles } = useGlobalFetcher("vehicles")
  const { data: allChecklistTypes } = useGlobalFetcher("checklistTypes")
  const locations = fetchLocationOptions([LocationType.DEPOT])

  return [
    new MultiselectFilter<ChecklistSelectionQuery, string>(
      "type",
      {
        name: "Job Type",
        pluralName: "Job Types",
        icon: icons.Check,
      },
      allChecklistTypes == null
        ? []
        : allChecklistTypes.map((type) => ({
            id: type,
            label: checklistTypeToLabel(type),
            value: type,
          }))
    ),
    new SiftableMultiselectFilter<ChecklistSelectionQuery, number>(
      "vehicleId",
      {
        name: "Vehicle",
        pluralName: "Vehicles",
        icon: icons.Bus,
      },
      vehicles == null
        ? []
        : vehicles.filter(isMaintainableVehicle).map((v) => ({
            id: `${v.id}`,
            label: v.plateNumber,
            value: v.id,
          }))
    ),
    new SiftableMultiselectFilter<ChecklistSelectionQuery, number>(
      "locationId",
      {
        name: "Location",
        pluralName: "Locations",
        icon: icons.Location,
      },
      locations == null
        ? []
        : locations.map((v) => ({
            id: `${v.id}`,
            label: v.name,
            value: v.id,
          }))
    ),
    new MultiselectFilter<ChecklistSelectionQuery, ChecklistCompletion>(
      "completion",
      {
        name: "State of Completion",
        pluralName: "States of Completion",
        icon: icons.ItemCompleted,
      },
      [
        {
          id: "incomplete",
          label: "Not Yet Completed",
          value: null,
          children: [
            {
              id: "not_started",
              label: "Not Started",
              value: ChecklistCompletion.NOT_STARTED,
            },
            {
              id: "partially_completed",
              label: "Partially Completed",
              value: ChecklistCompletion.PARTIALLY_COMPLETED,
            },
          ],
        },
        {
          id: "completed",
          label: "Completed",
          value: ChecklistCompletion.COMPLETED,
        },
      ]
    ),
  ]
}
